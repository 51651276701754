import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import SEO from '../components/seo';
import Banner from '../components/Banner';
import Layout from '../components/Layout';

const NotFoundPage = ({ className }) => (
  <Layout>
    <SEO title="404 Not found" />

    <header className={className}>
      <Banner title="Looks like you're lost friend">
        <AniLink fade to="/" className="btn-primary">
          Back to home page
        </AniLink>
      </Banner>
    </header>
  </Layout>
);

export default styled(NotFoundPage)`
  background: var(--mainEarth);
  min-height: 32.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
